import React, {useCallback, useContext, useMemo} from "react"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import useDetectMobileScreen from "../../util/hooks/useDetectMobileScreen";
import QueueUpNextItem from "./UpNextItem";
import {MediaQueueWrapperContext} from "./state/MediaQueueWrapper";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import {COLLAPSE_ICON, EXPAND_ICON} from "../../util/Util";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";
import { ReactSortable } from "react-sortablejs";
import ListGroup from "react-bootstrap/ListGroup";
import {List} from "semantic-ui-react";


export default function QueueUpNext() {
    let queueContext = useContext(MediaQueueWrapperContext)
    let queueData = queueContext.data;

    let {isMobile} = useDetectMobileScreen();
    let [queueCollapsed, setQueueCollapsed] = useStateWithSessionStorage("collapse_queue", false);

    let className = useMemo(() => {
        let htmlClass = "visible_up_next";
        if (!isMobile) {
            htmlClass += " queue_up_next_desktop"
        }
        return htmlClass;
    }, [isMobile])

    let header = (
        <Container>
            <Row style={{justifyContent: "space-between"}}>
                <div>
                    <Button variant={queueCollapsed ? "primary" : "secondary"} onClick={() => setQueueCollapsed(!queueCollapsed)}>
                        {queueCollapsed ? (EXPAND_ICON) : (COLLAPSE_ICON)}
                    </Button>

                    <b>Queue</b> ({queueData.playIndex+1}/{queueData.queue.length})
                </div>
                <div>
                    <Button variant={"danger"} onClick={queueContext.clearQueue}>Clear Queue</Button>
                </div>
            </Row>
        </Container>
    )
    let body = isMobile ? (
        <>
            <List className={"queue_list"}>
                {queueData.queue.map((media, index) => {
                    return (
                        <QueueUpNextItem media={media} key={index} index={index}/>
                    )
                })}
            </List>
        </>
        ) : (
        <>
        <List className={"queue_list"}>
            <ReactSortable group={"queue_group"} animation={400}
                           list={queueData.queue} setList={queueContext.reorderQueue}>
                {queueData.queue.map((media, index) => {
                    return (
                        <div key={media.src}>
                            <QueueUpNextItem media={media} key={index} index={index}/>
                        </div>
                    )
                })}
            </ReactSortable>
        </List>
        </>
    )
    return queueData.queue.length > 0 && (
        <Row id={"queue_up_next"}
             className={className}>
            <Col className={"queue_col"}>
                {/*<CollapsableCard header={header} body={body}>*/}
                {/*</CollapsableCard>*/}
                <Card>
                    <Card.Header>
                        {header}
                    </Card.Header>
                    {!queueCollapsed &&
                        <Card.Body>
                            {body}
                        </Card.Body>
                    }
                </Card>
            </Col>
        </Row>
    )
}

