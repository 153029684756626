import Button from "react-bootstrap/Button";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import QueueUpNext from "./QueueUpNext";

import {Container} from "react-bootstrap";
import {CustomIframe} from "./util/CustomIframe";
import {MdQueue} from "react-icons/md";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RedditLink from "../roundup/RedditLink";
import MusicMirrors from "./util/MusicMirrors";
import ControlButtons from "./ControlButtons";
import {COLLAPSE_ICON, EXPAND_ICON, WARNING_TOAST} from "../../util/Util";
import {MediaQueueWrapperContext} from "./state/MediaQueueWrapper";
import {SpotifyContext} from "./spotify/SpotifyConnect";
import {BiLinkExternal} from "react-icons/bi";

import styles from "../../css/media_queue.scss"
import {MyToastContext} from "../../util/context/MyToastContext";
import useDetectMobileScreen from "../../util/hooks/useDetectMobileScreen";
import {PiPushPinSimpleFill, PiPushPinSimpleSlashFill} from "react-icons/pi";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";

export default function MediaQueue(props) {
    let {spotifyConnect} = useContext(SpotifyContext);
    let queueContext = useContext(MediaQueueWrapperContext);
    let queueData = queueContext.data;
    let currentMedia = queueContext.getCurrentItem()
    let currentRedditPost = currentMedia?.post;
    let [isSticky, setIsSticky] = useStateWithSessionStorage("mq-sticky", true)
    // let [isSticky, setIsSticky] = useState(true)

    let {isMobile} = useDetectMobileScreen();

    let mirrorsExist = useMemo(
        () => currentRedditPost?.mirrors && currentRedditPost?.mirrors.length > 0,
        [currentRedditPost?.mirrors])

    function onMediaEnd() {
        queueContext.nextQueueItem();
    }
    let toastContext = useContext(MyToastContext);

    useEffect(() => {
        // if the media queue is expanded on mobile: disable scroll on the body
        if (queueData.isVisible && isMobile && isSticky) {
            // document.querySelector("body").classList.add("no_scroll")
        } else {
            // document.querySelector("body").classList.remove("no_scroll")
        }
    }, [queueData.isVisible, isMobile, isSticky])

    let controlButtons = (
        <ControlButtons
            isMuted={queueData.isMuted}
            isPlaying={queueData.isPlaying}
            onPlay={() => {
                spotifyConnect.resetFailedPlayCount()
                queueContext.togglePlay()
                if (queueContext.getCurrentItemUrl().includes("bandcamp.com")) {
                    toastContext.addToast("This button doesn't work with bandcamp. Use the bandcamp controls.", WARNING_TOAST)
                }
            }}
            onNext={queueContext.nextQueueItem}
            onPrev={queueContext.prevQueueItem}
            onMute={queueContext.toggleMute}
        />
    )
    let getCollapseAndStickyButtons = useCallback(() => {
        return <Col xs={3} md={2} className={"collapse-col"}>
            <Row className={"row-no-margin row-no-wrap"}>
                <Button variant={queueData.isVisible ? "secondary" : "primary"}
                        onClick={queueContext.toggleShowMediaQueue}>
                    {queueData.isVisible ? COLLAPSE_ICON : EXPAND_ICON}
                </Button>

                {isMobile && (
                    <Button variant={isSticky ? "secondary" : "primary"}
                            onClick={() => setIsSticky(!isSticky)}>
                        {isSticky ? <PiPushPinSimpleSlashFill/> : <PiPushPinSimpleFill/>}
                    </Button>
                )}
            </Row>
        </Col>
    }, [queueData.isVisible, isMobile, isSticky])


    return <Container fluid id={"queue_wrapper"}
                      className={isSticky ? "sticky" : ""}>
                      {/*className={"sticky"}>*/}

        {/*Full size version - top row */}
        {queueData.isVisible && (
            <Row className={"topRowFullSize"} style={{paddingBottom: "1em"}}>
                {/*Collapse button*/}
                {getCollapseAndStickyButtons()}

                {/* Full size: Control buttons*/}
                <Col xs={9} md={5}>
                    {controlButtons}
                </Col>

                {/*Mirrors*/}
                <Col xs={12} md={5}>
                    {mirrorsExist &&
                        <MusicMirrors post={currentRedditPost} usePopup={true} icon={
                            <div style={{paddingTop: ".75rem"}}>
                                <BiLinkExternal/>
                            </div>}/>
                    }
                </Col>
            </Row>
            )}

        {/*todo fix deezer embed
            <iframe title="deezer-widget" src="https://widget.deezer.com/widget/dark/album/565889181" width="100%" height="300" frameborder="0" allowtransparency="true" allow="encrypted-media; clipboard-write"></iframe>
        */}

        {/*Iframe*/}
        {queueData.queue && queueData.queue.length > 0 ? (
            <Row className={"row-no-margin " + (!queueData.isVisible ? "hidden" : "")}>
                <Col xs={12} className={"no_padding"}>
                    <CustomIframe onEnd={onMediaEnd}/>
                </Col>
            </Row>
        ) : queueData.isVisible && (
            <Row className={"row-no-margin"}>
                <span>The queue is empty, you can add to it by clicking this button &nbsp;</span>
                <Button className={"expand_media_button"} size={"sm"} style={{clear: "both"}}>
                    <MdQueue/>
                </Button>
                <span> &nbsp;beneath links that support it</span>
            </Row>
        )}

        {/*Up next*/}
        {queueData.isVisible &&
            <>
            <Row xs={12} md={queueData.isVisible ? 12 : 6} >
                <Col>
                    <RedditLink id={"bottom_reddit_link"}
                                post={currentRedditPost}
                                includeTitle={true}
                    />
                </Col>
            </Row>
            <QueueUpNext/>
            </>
        }

            {/* Minimized media queue*/}
        {!queueData.isVisible && (
            <>
                <Row id={"sticky_collapse_row"}>
                    {getCollapseAndStickyButtons()}

                    {/*(minified player) play / pause buttons*/}
                    <Col xs={9} md={6}>
                        {controlButtons}
                    </Col>
                    <Col xs={12} md={6} className={"alignContentCenter"}>
                        <RedditLink post={currentRedditPost}
                                    includeTitle={true}
                        />
                    </Col>
                </Row>
            </>
            )}

    </Container>

}


