import React, {useContext, useMemo} from "react";
import useDetectMobileScreen from "../../util/hooks/useDetectMobileScreen";
import Button from "react-bootstrap/Button";
import { MdDragHandle } from "react-icons/md";
import {FaMinus} from "react-icons/fa";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getSiteIcon} from "./util/SiteIcons";
import {MediaQueueWrapperContext} from "./state/MediaQueueWrapper";
import {List} from "semantic-ui-react";

export default function QueueUpNextItem(props) {
    let queueContext = useContext(MediaQueueWrapperContext)
    let media = props.media;
    let index = props.index;
    let {isMobile} = useDetectMobileScreen();

    let isCurrentItem = useMemo(() => {
        return index === queueContext.data.playIndex;
    }, [index, queueContext.data.playIndex])


    const dequeueButton = (
        <div className={"dequeue_button btn btn-danger"}
             onClick={(event) => {
                 event.stopPropagation()
                 queueContext.removeFromQueue(media)
             }}>
            <FaMinus/>
        </div>)

    const titleDiv = <>
        {media?.post?.title}
    </>

    const dragAnchor = <Button><MdDragHandle></MdDragHandle></Button>
    return (
        <Button key={props.key} onClick={!isCurrentItem ? () => queueContext.goToItemInQueue(index) : ()=>{}}
                className={"queue_item_button " + (isCurrentItem ? "current_queue_item" : "")}>
            <List.Item className={"queue_item"}>
                <>
                    {isMobile ? (
                        // On mobile the dequeue button and the site icon should be stacked vertically
                        <Row xs={12}>
                            {/*<Col xs={1}>*/}
                            {/*    {dragAnchor}*/}
                            {/*</Col>*/}
                            <Col xs={1}>
                                {getSiteIcon(media)}
                            </Col>
                            <Col xs={10} className={"alignTextStart"}>
                                {titleDiv}
                            </Col>
                            <Col xs={1}>
                                {dequeueButton}
                            </Col>
                        </Row>
                    ) : (
                        //on Desktop the site icon should come after the title.
                        <Row>
                            {/*<Col xs={1}>*/}
                            {/*    {dragAnchor}*/}
                            {/*</Col>*/}
                            <Col xs={2}>
                                {getSiteIcon(media)}
                            </Col>

                            <Col xs={8} className={"alignTextStart"}>
                                {titleDiv}
                            </Col>
                            <Col xs={2}>
                                {dequeueButton}
                            </Col>
                        </Row>
                    )}
                </>

            </List.Item>
        </Button>
    )

}