import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";

import ReactPlayer from "react-player/lazy";
import useWindowDimensions from "./UseWindowDimensions";

import {R4RSpotifyPlayer} from "../spotify/R4RSpotifyPlayer";
import {logger} from "../../../util/Util";
import {MediaQueueWrapperContext} from "../state/MediaQueueWrapper";

export function CustomIframe(props) {
    let queueContext = useContext(MediaQueueWrapperContext);
    let media = queueContext.getCurrentItem()
    let iframeRef = useRef();
    let reactPlayerRef = useRef();
    let isPlayingRef = useRef(false);
    let [muted, setMuted] = useState(false);
    let [shouldPlay, setShouldPlay] = useState(false);

    const {width, height, oWidth, oHeight} = useWindowDimensions();

    let isPlaylist = useMemo(() => {
        if (!media || !media.mediaType) {
            return false;
        }
        let validMediaTypes = ["playlist", "album", "playlist_or_ep", "music.album", "music.playlist"];
        // logger(`is playlist or album? media type: ${media.mediaType}, answer: ${isTrue}`)
        return validMediaTypes.includes(media.mediaType);
    }, [media]);

    useEffect(() => {
        setMuted(queueContext.data.isMuted)
    }, [queueContext.data.isMuted])

    useEffect(() => {
        setShouldPlay(queueContext.data.shouldBePlaying)
    }, [queueContext.data.shouldBePlaying])


    let playerWidth = useMemo(() => {
        // 275 < x < 1036
        // the min number is arbitrary
        let w = Math.min(width - 20, 1036)
        w = Math.max(w, 275)
        return w;
        // return width
    }, [width])

    let src = queueContext.getCurrentItemUrl()
    let playerHeight = useMemo(() => {
        if (src.includes("soundcloud.com")) {
            return 350
        }
        return 500
    }, [src])

    let siteSpecificConfig = {
        soundcloud: {
            options: {
                visual: false,
                auto_play: true,
                sharing: true,
                buying: true,
                download: true,
                show_artwork: true,
                show_user: true
            }
        },
        youtube: {
            playerVars: {
                autoplay: 1,
                controls: 1
            }
        },
        vimeo: {
            playerOptions: {
                autoplay: true,
                color: "#ADFF2F"
            }
        },
        mixcloud: {
            options: {
                autoplay: true
            }
        }
    }

    /**
     * Determines if we can use the react-player library for embedding this link
     */
    function canUseReactPlayer() {
        // TODO all supported media is listed on github. Stay up to date:
        //  https://github.com/CookPete/react-player#supported-media
        let validHostnames = ["youtube.com", "youtu.be", "soundcloud.com", "vimeo.com", "dailymotion.com", "facebook.com",
            "streamable.com", "mixcloud.com", "twitch.tv", "v.redd.it", "pandora.com"]; //"soundcloud.app.goo.gl",
        for (const index in validHostnames) {
            let hostname = validHostnames[index]
            if (media["src"]?.includes(hostname)) {
                return true;
            }
        }
        return false;
    }

    function onPlay() {
        // this is called when the 'play' event is triggered
        queueContext.setPlayingDisplay()
        setShouldPlay(true)
        isPlayingRef.current = true;
    }

    function onPause() {
        queueContext.setPausedDisplay()
        setShouldPlay(false)
        isPlayingRef.current = false;
    }

    function onEnded() {
        logger("media ended");
        isPlayingRef.current = false;
        if (!isPlaylist) {
            logger("Not a playlist, calling on end")
            queueContext.nextQueueItem()
        } else {
            // wait a second, check if another song gets played
            logger("This is a playlist, waiting a second")
            setTimeout(() => {
                if (!isPlayingRef.current) {
                    logger("playlist has ended I think")
                    props.onEnd();
                } else {
                    logger("playlist is still going")
                    // wait another second and check again
                    setTimeout(() => {
                        if (!isPlayingRef.current) {
                            logger("playlist has ended I think")
                            props.onEnd();
                        }
                        logger("playlist is still STILL going")
                    }, 500);
                }
            }, 500);
        }
    }

    function onError(e) {
        logger("onError");
        logger(e);
    }

    function onReady(e) {
        logger("react player is ready", e.props.url, "shouldBePlaying?", shouldPlay);
    }

    /**
     * Disable the play button. This function is passed down to children
     */
    let callbackDisablePlay = useCallback((shouldDisable) => {
        queueContext.disablePlayButtonToggle(shouldDisable)
    }, [queueContext])


    if (canUseReactPlayer()) {
        return (
            <ReactPlayer url={src}
                         className={"react-player"}
                         controls={true}
                         onPlay={onPlay} onPause={onPause} onEnded={onEnded} onError={onError} onReady={onReady}
                         playing={shouldPlay}
                         muted={muted}
                         volume={1}
                         config={siteSpecificConfig}
                         width={playerWidth}
                         height={playerHeight}
                         ref={reactPlayerRef}
                         playsinline={true}
                         pip={true}
                         stopOnUnmount={false}
            />
        )
    } else if (src.includes("spotify.com")) {
        return (
            <R4RSpotifyPlayer media={media}
                              onPlay={onPlay} onPause={onPause} onEnded={onEnded} onError={onError}
                              onReady={onReady}
                              playing={shouldPlay}
                              muted={muted}
                              disablePlayCallback={callbackDisablePlay}
                              volume={1}/>
        )
    }
        // } else if (src.includes("music.apple.com")) {
        //TODO:
        // https://developer.apple.com/documentation/applemusicapi/generating_developer_tokens
        //  https://js-cdn.music.apple.com/musickit/v3/docs/index.html?path=/story/get-started--page

        //     return (
        //         <apple-music-card-player></apple-music-card-player>
    //     )
    else {
        logger("USING regular old IFRAME for url: " + src)
        let w = "200px"
        let h = "100px"
        let bandcampTrack = false, bandcampAlbum = false;
        if (src.includes("bandcamp.com")) {
            if (src.includes("/track=")) {
                w = "100%"
                h = "42px"
                bandcampTrack = true
            } else if (src.includes("/album=")) {
                w = "350px"
                h = "470px"
                bandcampAlbum = true
            }
        }
        return (
            // TODO make it responsive: https://www.w3schools.com/howto/howto_css_responsive_iframes.asp
            <div className={"responsive_iframe_container " + (bandcampTrack ? 'iframe_bandcamp_track' : bandcampAlbum ? 'iframe_bandcamp_album' : '')}>
                <iframe title={media && media.title ? media.title : "embedded content"}
                        style={{border: "0"}}
                        className={"responsive_iframe"}
                        id={"media-iframe"}
                        ref={iframeRef}
                        src={src}
                        width={w} height={h}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; storage-access"
                        allowFullScreen
                />
            </div>
        )
    }

}
