import useDetectMobileScreen, {MD} from "../../../util/hooks/useDetectMobileScreen";
import {logger} from "../../../util/Util";
import React, {useCallback, useContext, useMemo} from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import {RiAlbumFill, RiUserVoiceFill} from "react-icons/ri";
import {BsMusicNoteBeamed} from "react-icons/bs";
import {SpotifyContext} from "./SpotifyConnect";
import {ListGroup} from "react-bootstrap";
import {MediaQueueWrapperContext} from "../state/MediaQueueWrapper";
import {FaPlay, FaPause} from "react-icons/fa";
import Anchor from "../../Anchor";


export function SpotifyNowPlaying() {
    let {spotifyConnect, spotifyState} = useContext(SpotifyContext);

    let queueContext = useContext(MediaQueueWrapperContext);
    let queueData = queueContext.data;
    let {breakpoint} = useDetectMobileScreen();

    let currentTrack = useMemo(() => {
        let nowPlaying = spotifyState.nowPlaying;
        let playerState = spotifyState.playerState;
        logger("playerState: [", playerState?.getAlbum(), playerState?.getTrack()?.name, "]",
            "nowPlaying: [", nowPlaying?.getAlbum(), nowPlaying?.getTrack()?.name, "]")
        if (nowPlaying?.getAlbumId() && !playerState?.getAlbumId()) {
            // logger("spnp: use nowPlaying")
            return spotifyState.nowPlaying.parsed
        }
        if (nowPlaying?.getAlbumId() === playerState?.getAlbumId()) {
            if (nowPlaying?.getTrackId() === playerState?.getTrackId()) {
                // same album, same song
                // logger("spnp: same album, same song use [nowPlaying]")
                return spotifyState.nowPlaying.parsed
            } else {
                // logger("spnp: same album, DIFF song use [playerState]")
                // same album, different song
                playerState.parsed.albumTracks = nowPlaying.parsed.albumTracks
                return playerState.parsed
            }
        } else {
            // spotify autoplay, or just playing the wrong queue item
            // we need to know which one. If spotify autoplay we should pause.
            // if wrong queue item we need to play the right one
            // logger("spnp: diff album, DIFF song (todo)")
            // playerState has the currently playing song (including the songs that are auto-played by spotify)
            // nowPlaying has the data for the queue item
            return spotifyState.isPush ? playerState.parsed : nowPlaying.parsed
        }
    }, [spotifyState])

    let isCurrentAlbumTrack = useCallback((track, index) => {
        // look at spotify state to see if this track is playing.
        return track && track.uri && spotifyState?.playerState?.getTrack()?.uri === track?.uri;
    }, [spotifyState])

    return (
        <Row className={""} noGutters={false}>
            {/*thumbnail*/}

            <img id={"player_album_image"}
                 className={"no_padding tertiary-container white_text " + (breakpoint.val <= MD.val ? 'col-3' : 'col-2')}
                 src={currentTrack?.thumbnailUrl} alt={currentTrack?.albumName}/>

            <Col className={"white_text song_info_wrapper"} xs={breakpoint.val <= MD.val ? 9 : 10}>
                <Row>
                    <Col xs={currentTrack?.albumTracks?.length ? 6 : 12} className={"song_details"}>
                        <Row className={"song_name"}>
                            <div>
                                <BsMusicNoteBeamed/>&nbsp;&nbsp;
                                <Anchor href={"https://open.spotify.com/artist/" + currentTrack?.songId} >
                                    {currentTrack?.songName}
                                </Anchor>
                            </div>
                        </Row>

                        <Row className={"artist_names"}>
                            <div>
                                <RiUserVoiceFill/>&nbsp;&nbsp;
                                {currentTrack?.artists?.map((nextArtist, index) => {
                                    return <Anchor key={nextArtist.link} href={nextArtist.link}>
                                        {nextArtist.name}{index+2 <= currentTrack?.artists.length ? ', ' : ''}
                                    </Anchor>
                                })}
                            </div>
                        </Row>
                        <Row className={"album_name"}>
                            <div>
                                <RiAlbumFill/>&nbsp;&nbsp;
                                <Anchor href={"https://open.spotify.com/album/" + currentTrack?.albumId}>
                                    {currentTrack?.albumName}
                                </Anchor>
                            </div>
                        </Row>
                    </Col>
                    {currentTrack?.albumTracks?.length > 0 &&
                        <Col xs={6} id={"album_tracks"}>
                            <ListGroup>
                                {currentTrack?.albumTracks.map((trk, index) => {
                                    let isPlaylist = !!trk.added_at;
                                    let thisTrack = isPlaylist ? trk.track : trk
                                    let isCurrent = isCurrentAlbumTrack(thisTrack, index)
                                    let isPlaying = queueData.isPlaying
                                    return (
                                        <Button key={thisTrack.id} className={"queue_item_button " + (isCurrent ? "current_queue_item" : "")}
                                                onClick={() => {
                                                    if (isCurrent) {
                                                        queueContext.togglePlay()
                                                    } else {
                                                        if (!queueData.shouldBePlaying) {
                                                            queueContext.togglePlay()
                                                        }
                                                        spotifyConnect.playUri(index)
                                                    }
                                                }}>
                                            <div  className={"queue_item"} style={{textAlign: "start", float: "left"}}>
                                                {isCurrent && isPlaying ? (
                                                    <>
                                                    <FaPause/>&nbsp;&nbsp;
                                                    </>) : null}
                                                {isPlaylist ? (
                                                    <>
                                                        {thisTrack.name}
                                                    </>
                                                ) : (
                                                    <>
                                                        {thisTrack.track_number}. {thisTrack.name}
                                                    </>
                                                    )}
                                            </div>
                                        </Button>
                                    )
                                })}
                            </ListGroup>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
